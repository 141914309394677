// Base URL for proxy server used for API requests
export const PROXY = process.env.REACT_APP_PROXY;

// Filename for the original zip file containing particles data
export const ORGINAL_ZIP = "particles.zip";

// Filename for the compressed zip file containing images
export const COMPRESSED_ZIP = "compressedImages.zip";

// CSS variables representing colors for different visibility modes
export const visibilityColors = {
    Default: {
        'altered material': '#F7931E', 
        'lithic': '#006837', 
        'juvenile': '#B81A5D', 
        'free crystal': '#29ABE2', 
        'Dome explosion': '#662D91', 
        'Lava fountaining': '#428ECB', 
        'Strombolian': '#B07CC4', 
        'Phreatic': '#6FC168', 
        'Plinian': '#EC1A21', 
        'Subplinian': '#E9911E', 
        'Chillán, Nevados de': '#DE3935', 
        'Merapi': '#EB5B2A', 
        'La Palma': '#EDB656', 
        'On-take': '#0BB490', 
        'Kelut': '#0CCACE', 
        'Pinatubo': '#1D9FD4', 
        'Soufrière Guadeloupe': '#6F8BE0', 
        'St. Helens': '#B07CC4', 
        'Stromboli': '#6FC168', 
        'Etna': '#EC1A21', 
        'Vesuvius': '#E9911E', 
        'KEL_16_DB2': '#9e0142',
        'MEA_22post_DB1': '#d53e4f',
        'KEL_16_DB3': '#f46d43',
        'MEA_22post_DB2': '#fdae61',
        'LAP_2_DB1': '#fee08b',
        'CIN_11_DB2': '#ffffbf',
        'CIN_11_DB15': '#e6f598',
        'ONT_10_DB1': '#abdda4',
        'PIN_3_DB1': '#66c2a5',
        'STH_12post_DB1': '#3288bd',
        'SOG_6_DB1': '#5e4fa2',
        'SOG_6_DB2': '#EC1A21',
        'TOB_6_DB1': '#428ECB',
        'Eruption History': '#327814',
        'Samples': '#B41414',
        'stats': '#006837',
    },
    Protanopia: {
        'altered material': '#CBCA3A', 
        'lithic': '#2D2D42', 
        'juvenile': '#73724C', 
        'free crystal': '#6162D4', 
        'Dome explosion': '#4D4C78', 
        'Lava fountaining': '#6263BC',
        'Strombolian': '#9999B2',  
        'Phreatic': '#92937D', 
        'Plinian': '#918F1F', 
        'Subplinian': '#C2C239', 
        'Chillán, Nevados de': '#969535', 
        'Merapi': '#ACAB35', 
        'La Palma': '#D5D46D', 
        'On-take': '#545598', 
        'Kelut': '#5E5FCD', 
        'Pinatubo': '#5556C7', 
        'Soufrière Guadeloupe': '#7B7BCB', 
        'St. Helens': '#9999B2', 
        'Stromboli': '#92937D', 
        'Etna': '#918F1F', 
        'Vesuvius': '#C2C239', 
        'KE-DB2': '#5A5832',
        'ME-DB1': '#93924A',
        'KE-DB3': '#B9B84D',
        'ME-DB2': '#DADA73',
        'CV-DB1': '#F1F09F',
        'NC-DB2': '#FEFFCE',
        'NC-DB15': '#ECECAE',
        'ON-DB1': '#C0C1B1',
        'PI-DB1': '#8D8EAC',
        'MS-DB1': '#5758B0',
        'SG-DB1': '#57578D',
        'SG-DB2': '#918F1F',
        'TO-DB1': '#6263BC',
        'Eruption History': '#50502C',
        'Samples': '#6E6D14',
        'stats': '#2D2D42',
    },
    Deuteranopia: {
        'altered material': '#D1D841', 
        'lithic': '#271F45', 
        'juvenile': '#7C8848', 
        'free crystal': '#5950D1', 
        'Dome explosion': '#505473', 
        'Lava fountaining': '#5E58B8', 
        'Strombolian': '#9CA0AE', 
        'Phreatic': '#8D8782', 
        'Plinian': '#9DAD1E', 
        'Subplinian': '#C8CE40', 
        'Chillán, Nevados de': '#A0AC36', 
        'Merapi': '#B5BF38', 
        'La Palma': '#D8DC72', 
        'On-take': '#4A3D9A', 
        'Kelut': '#5345CC', 
        'Pinatubo': '#4D44C4', 
        'Soufrière Guadeloupe': '#7977C6', 
        'St. Helens': '#9CA0AE', 
        'Stromboli': '#8D8782', 
        'Etna': '#9DAD1E', 
        'Vesuvius': '#C8CE40',
        'KE-DB2': '#636E2E',
        'ME-DB1': '#9CA749',
        'KE-DB3': '#C1CB4F',
        'ME-DB2': '#DFE578',
        'CV-DB1': '#F2F5A4',
        'NC-DB2': '#FFFFD2',
        'NC-DB15': '#EBEAB3',
        'ON-DB1': '#BDBAB5',
        'PI-DB1': '#8881AD',
        'MS-DB1': '#524BAD',
        'SG-DB1': '#585989',
        'SG-DB2': '#9DAD1E',
        'TO-DB1': '#5E58B8',
        'Eruption History': '#4C4732',
        'Samples': '#788414',
        'stats': '#271F45',
    },
    Tritanopia: {
        'altered material': '#F15055', 
        'lithic': '#054C4E', 
        'juvenile': '#B03F3D', 
        'free crystal': '#2FCAC7', 
        'Dome explosion': '#636561', 
        'Lava fountaining': '#45B0AE',
        'Strombolian': '#ADA4A1',  
        'Phreatic': '#738E92', 
        'Plinian': '#E11D1D', 
        'Subplinian': '#E44F54', 
        'Chillán, Nevados de': '#D53636', 
        'Merapi': '#E33F41', 
        'La Palma': '#EA7F83', 
        'On-take': '#139FA1', 
        'Kelut': '#15CCCC', 
        'Pinatubo': '#23BDBA', 
        'Soufrière Guadeloupe': '#70BBB7', 
        'St. Helens': '#ADA4A1', 
        'Stromboli': '#738E92', 
        'Etna': '#E11D1D', 
        'Vesuvius': '#E44F54',
        'KE-DB2': '#962523',
        'ME-DB1': '#CD4746',
        'KE-DB3': '#ED5556',
        'ME-DB2': '#F98285',
        'CV-DB1': '#FCAFB3',
        'NC-DB2': '#FFDADD',
        'NC-DB15': '#E6C0C4',
        'ON-DB1': '#ADBCBF',
        'PI-DB1': '#6AB1B2',
        'MS-DB1': '#36A6A3',
        'SG-DB1': '#5D7E7A',
        'SG-DB2': '#E11D1D',
        'TO-DB1': '#45B0AE',
        'Eruption History': '#353F43',
        'Samples': '#AC1414',
        'stats': '#054C4E',
    },
    Achromatopsia: {
        'altered material': '#A3A3A3', 
        'lithic': '#434343', 
        'juvenile': '#505050', 
        'free crystal': '#8A8A8A', 
        'Dome explosion': '#494949', 
        'Lava fountaining': '#7E7E7E', 
        'Strombolian': '#939393', 
        'Phreatic': '#9E9E9E', 
        'Plinian': '#595959', 
        'Subplinian': '#3E3E3E', 
        'Chillán, Nevados de': '#696969', 
        'Merapi': '#808080', 
        'La Palma': '#BBBBBB', 
        'On-take': '#7D7D7D', 
        'Kelut': '#919191', 
        'Pinatubo': '#7E7E7E', 
        'Soufrière Guadeloupe': '#8C8C8C', 
        'St. Helens': '#939393', 
        'Stromboli': '#9E9E9E', 
        'Etna': '#595959', 
        'Vesuvius': '#3E3E3E',
        'KE-DB2': '#373737',
        'ME-DB1': '#6D6D6D',
        'KE-DB3': '#909090',
        'ME-DB2': '#BCBCBC',
        'CV-DB1': '#DFDFDF',
        'NC-DB2': '#F7F7F7',
        'NC-DB15': '#E5E5E5',
        'ON-DB1': '#C7C7C7',
        'PI-DB1': '#A3A3A3',
        'MS-DB1': '#747474',
        'SG-DB1': '#5C5C5C',
        'SG-DB2': '#595959',
        'TO-DB1': '#7E7E7E',
        'Eruption History': '#575757',
        'Samples': '#434343',
        'stats': '#434343',
    },
    Protanomaly: {
        'altered material': '#E4B42C', 
        'lithic': '#13453D', 
        'juvenile': '#9B4E54', 
        'free crystal': '#407FDB', 
        'Dome explosion': '#5B3F84', 
        'Lava fountaining': '#4F74C3', 
        'Strombolian': '#A68DBB', 
        'Phreatic': '#7EA573', 
        'Plinian': '#C55F20', 
        'Subplinian': '#D8AE2C', 
        'Chillán, Nevados de': '#BF6F35', 
        'Merapi': '#D08A30', 
        'La Palma': '#E2C862', 
        'On-take': '#297B94', 
        'Kelut': '#2E8ACD', 
        'Pinatubo': '#3473CD', 
        'Soufrière Guadeloupe': '#7481D5', 
        'St. Helens': '#A68DBB', 
        'Stromboli': '#7EA573', 
        'Etna': '#C55F20', 
        'Vesuvius': '#D8AE2C',
        'KE-DB2': '#813539',
        'ME-DB1': '#B9704C',
        'KE-DB3': '#DB9948',
        'ME-DB2': '#EEC86A',
        'CV-DB1': '#F8E995',
        'NC-DB2': '#FEFFC7',
        'NC-DB15': '#E8F0A3',
        'ON-DB1': '#B4CCAB',
        'PI-DB1': '#76A3A8',
        'MS-DB1': '#416BB6',
        'SG-DB1': '#5B5397',
        'SG-DB2': '#C55F20',
        'TO-DB1': '#4F74C3',
        'Eruption History': '#3E6020',
        'Samples': '#964914',
        'stats': '#13453D',
    },
    Deuteranomaly: {
        'altered material': '#E3AC2E', 
        'lithic': '#144D3D', 
        'juvenile': '#984253', 
        'free crystal': '#4389DA', 
        'Dome explosion': '#5A3B82', 
        'Lava fountaining': '#517AC2', 
        'Strombolian': '#A589B9', 
        'Phreatic': '#7FAB74', 
        'Plinian': '#C25020', 
        'Subplinian': '#D7A72E', 
        'Chillán, Nevados de': '#BD6335', 
        'Merapi': '#CE8030', 
        'La Palma': '#E2C463', 
        'On-take': '#2C8895', 
        'Kelut': '#3298CD', 
        'Pinatubo': '#377DCC', 
        'Soufrière Guadeloupe': '#7483D3', 
        'St. Helens': '#A589B9', 
        'Stromboli': '#7FAB74', 
        'Etna': '#C25020', 
        'Vesuvius': '#D7A72E',
        'KE-DB2': '#7E2938',
        'ME-DB1': '#B6644C',
        'KE-DB3': '#D98F48',
        'ME-DB2': '#EDC26B',
        'CV-DB1': '#F8E797',
        'NC-DB2': '#FFFFC8',
        'NC-DB15': '#E9F1A5',
        'ON-DB1': '#B5D0AC',
        'PI-DB1': '#78AAA9',
        'MS-DB1': '#4371B5',
        'SG-DB1': '#5B5296',
        'SG-DB2': '#C25020',
        'TO-DB1': '#517AC2',
        'Eruption History': '#406522',
        'Samples': '#943D14',
        'stats': '#144D3D',
    },
    Tritanomaly: {
        'altered material': '#F37333', 
        'lithic': '#035A3F', 
        'juvenile': '#B22B50', 
        'free crystal': '#2DB9D7', 
        'Dome explosion': '#64477E', 
        'Lava fountaining': '#449EBF',
        'Strombolian': '#AE8FB6',  
        'Phreatic': '#71A978', 
        'Plinian': '#E51B1F', 
        'Subplinian': '#E67233', 
        'Chillán, Nevados de': '#D83735', 
        'Merapi': '#E64D32', 
        'La Palma': '#EB9C67', 
        'On-take': '#10AA96', 
        'Kelut': '#12CBCD', 
        'Pinatubo': '#21ADCA', 
        'Soufrière Guadeloupe': '#6FA1D0', 
        'St. Helens': '#AE8FB6', 
        'Stromboli': '#71A978', 
        'Etna': '#E51B1F', 
        'Vesuvius': '#E67233',
        'KE-DB2': '#981236',
        'ME-DB1': '#D0424B',
        'KE-DB3': '#EF614A',
        'ME-DB2': '#FA996F',
        'CV-DB1': '#FDC99A',
        'NC-DB2': '#FEEDCA',
        'NC-DB15': '#E6DCA9',
        'ON-DB1': '#ACCDAE',
        'PI-DB1': '#69BAAA',
        'MS-DB1': '#3496B3',
        'SG-DB1': '#5D6592',
        'SG-DB2': '#E51B1F',
        'TO-DB1': '#449EBF',
        'Eruption History': '#345D26',
        'Samples': '#AE1414',
        'stats': '#035A3F',
    },
    Achromatomaly: {
        'altered material': '#C99C66', 
        'lithic': '#24543D', 
        'juvenile': '#7F3756', 
        'free crystal': '#5E99B2', 
        'Dome explosion': '#563C69', 
        'Lava fountaining': '#6285A0',
        'Strombolian': '#A088A9',  
        'Phreatic': '#88AE85', 
        'Plinian': '#9C3C3F', 
        'Subplinian': '#C09863', 
        'Chillán, Nevados de': '#9E5351', 
        'Merapi': '#B06F59', 
        'La Palma': '#D2B98D', 
        'On-take': '#499685', 
        'Kelut': '#54ABAC', 
        'Pinatubo': '#518DA4', 
        'Soufrière Guadeloupe': '#7E8BB2', 
        'St. Helens': '#A088A9',
        'Stromboli': '#88AE85', 
        'Etna': '#9C3C3F', 
        'Vesuvius': '#C09863', 
        'KE-DB2': '#661E3C',
        'ME-DB1': '#9C575F',
        'KE-DB3': '#BD806D',
        'ME-DB2': '#DAB692',
        'CV-DB1': '#EDDFB8',
        'NC-DB2': '#FBFBDD',
        'NC-DB15': '#E5ECC2',
        'ON-DB1': '#BAD1B7',
        'PI-DB1': '#87B1A3',
        'MS-DB1': '#567D95',
        'SG-DB1': '#5D567C',
        'SG-DB2': '#9C3C3F',
        'TO-DB1': '#6285A0',
        'Eruption History': '#466638',
        'Samples': '#762E2E',
        'stats': '#24543D',
    },
}